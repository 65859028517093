<template>
  <div class="categories">
    <div class="category-section">
      <!--      Upper Text -->
      <br />
      <div v-if="$store.getters.stateAreas.length > 0" class="welcome-tittle-category">
        Categorias disponibles dentro de "{{
          $store.getters.stateAreas.find(
            (area) => area.id === $route.params.area_id
          ).name
        }}"
        <b-button
          variant="link"
          :disabled="loading"
          class="category-options-buttons welcome-tittle-assets float-right"
          @click="goBack"
        >
          Regresar
        </b-button>
      </div>
      <br />

      <!--      Current categories section-->

      <b-row no-gutters v-if="!loading || updated">
        <b-col
          cols="12"
          sm="4"
          v-for="(category, index) in categories"
          :key="index"
          class="category-element"
        >
          <b-card
            bg-variant="dark"
            text-variant="white"
            class="category-card-element"
          >
            <b-row>
              <b-col cols="8" class="category-card-tittle">
                <div>
                  {{ category.name }}
                </div>
              </b-col>
              <b-col cols="2" class="category-card-options">
                <b-dropdown
                  dropleft
                  variant="link"
                  no-caret
                  menu-class="dropdown-menu-dark"
                >
                  <template #button-content>
                    <b-icon
                      icon="three-dots-vertical"
                      class="category-options-buttons"
                    ></b-icon>
                  </template>
                  <b-dropdown-item-button
                    link
                    item
                    @click="editCategory(category)"
                  >
                    <b-icon icon="pencil"></b-icon>
                    Editar
                  </b-dropdown-item-button>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item-button link @click="deleteCategory(index)">
                    <b-icon icon="trash"></b-icon>
                    Eliminar
                  </b-dropdown-item-button>
                </b-dropdown>
              </b-col>
            </b-row>
            <br />
            <b-card-text class="text-left">
              {{ category.description }}
              <br/>
              {{ category.assets_dimensions }}
            </b-card-text>

            <template #footer>
              <b-button
                :disabled="loading"
                variant="primary"
                block
                :to="{
                  name: 'Manager',
                  params: {
                    area_id: $route.params.area_id,
                    category_id: category.id,
                  },
                }"
              >
                Ingresar
              </b-button>
            </template>
          </b-card>
        </b-col>

        <!--      Add new category Button -->

        <b-col cols="12" sm="4" class="category-element">
          <b-card
            v-if="!loading || updated"
            bg-variant="dark"
            text-variant="white"
            class="category-card-element"
          >
            <div class="category-card-tittle d-block">
              Agregar nueva categoria
            </div>
            <br />
            <b-button
              :disabled="loading"
              v-b-modal.modal-create
              pill
              variant="primary"
              class="category-button"
            >
              +
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <!--      New Category section modal-->

    <b-modal
      v-model="showCreate"
      id="modal-create"
      centered
      content-class="modal-dark"
    >
      <template #modal-header>
        <h4>Agregar nueva categoria</h4>
      </template>
      <b-form-input
        :disabled="loading"
        v-model="tmpCategory.name"
        placeholder="Nombre nueva categoria"
      ></b-form-input>
      <br />
      <b-form-input
        v-model="tmpCategory.description"
        placeholder="Descripcion"
      ></b-form-input>
      <br />
      <b-form-input
          v-model="tmpCategory.assets_dimensions"
          placeholder="Dimensiones de los recursos"
      ></b-form-input>
      <template #modal-footer>
        <b-button
          :disabled="loading"
          variant="link"
          class="category-options-buttons"
          @click="showCreate = false"
        >
          Cancelar
        </b-button>
        <b-button
          pill
          :disabled="loading"
          variant="primary"
          @click="saveCategory"
        >
          <span v-if="loading">
            <b-spinner variant="dark" small></b-spinner>
          </span>
          <span v-else> Crear nueva categoria </span>
        </b-button>
      </template>
    </b-modal>

    <!--     Edit category section modal-->

    <b-modal
      v-model="showEdit"
      id="modal-edit"
      centered
      content-class="modal-dark"
    >
      <template #modal-header>
        <h4>Editar categoria de trabajo</h4>
      </template>
      <b-form-input
        :disabled="loading"
        v-model="tmpCategory.name"
        placeholder="Nombre de la categoria"
      ></b-form-input>
      <br />
      <b-form-input
        :disabled="loading"
        v-model="tmpCategory.description"
        placeholder="Descripcion"
      ></b-form-input>
      <br />
      <b-form-input
          :disabled="loading"
          v-model="tmpCategory.assets_dimensions"
          placeholder="Dimensiones de los recursos"
      ></b-form-input>
      <template #modal-footer>
        <b-button
          :disabled="loading"
          variant="link"
          class="category-options-buttons"
          @click="showEdit = false"
        >
          Cancelar
        </b-button>
        <b-button
          pill
          :disabled="loading"
          variant="primary"
          @click="saveCategory"
        >
          <span v-if="loading">
            <b-spinner variant="dark" small></b-spinner>
          </span>
          <span v-else> Cambiar </span>
        </b-button>
      </template>
    </b-modal>

    <!--    Page loader-->

    <b-spinner
      v-if="loading && !updated"
      variant="primary"
      label="Text Centered"
      class="loader"
    ></b-spinner>
  </div>
</template>

<script>
export default {
  name: "Categories",
  computed: {
    loading() {
      return this.$store.getters.isLoading;
    },
    categories: {
      get() {
        return this.$store.getters.stateCategories;
      },
      set(value) {
        return this.$store.commit("setCategories", value);
      },
    },
  },
  data() {
    return {
      tmpCategory: {
        id: null,
        name: "",
        area_id: null,
        description: "",
        assets_dimensions: ""
      },
      updated: false,
      showCreate: false,
      showEdit: false,
    };
  },
  beforeCreate() {
    if (this.$store.getters.stateAreas.length === 0) {
      this.$router.push({ name: "Board" });
    }

    this.$store.dispatch("getAvailableCategories", {
      token: this.$store.getters.stateToken,
      area: this.$route.params.area_id,
    });
  },
  methods: {
    async saveCategory() {
      this.updated = true;

      if (this.tmpCategory.id != null) {
        await this.$store.dispatch("editCategory", {
          token: this.$store.getters.stateToken,
          data: this.tmpCategory,
        });
        this.showEdit = false;
      } else {
        this.tmpCategory.area_id = this.$route.params.area_id;
        await this.$store.dispatch("createNewCategory", {
          token: this.$store.getters.stateToken,
          data: this.tmpCategory,
        });
        this.showCreate = false;
      }

      this.tmpCategory = { id: null, name: "", area_id: null, description: "", assets_dimensions: "" };
    },
    deleteCategory(index) {
      this.updated = true;
      this.$bvModal
        .msgBoxConfirm("Estas seguro de que deseas eliminar la categoria?", {
          contentClass: "modal-dark",
          okVariant: "danger",
          okTitle: "Eliminar",
          cancelTitle: "Cancelar",
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$store.dispatch("deleteCategory", {
              token: this.$store.getters.stateToken,
              data: { category_id: this.categories[index].id },
              index: index,
            });
          }
        })
        .catch(() => {
          alert("Opps... algo ha salido mal, intentelo mas tarde");
        });
    },
    editCategory(category) {
      this.tmpCategory = category;
      this.updated = true;
      this.showEdit = true;
    },
    goBack() {
      this.$store.commit("setAreas", []);
      this.$router.push({ name: "Board" });
    },
  },
};
</script>

<style scoped>
.category-section {
  max-width: 90%;
  justify-content: center;
  margin: auto;
}

.category-element {
  margin-bottom: 3%;
  padding-right: 5px;
  padding-left: 0;
  max-width: 100%;
}

.welcome-tittle-category {
  color: white;
  text-align: left;
  font-size: 14px;
}

.category-button {
  height: 60px;
  width: 60px;
  font-weight: bold;
  color: white;
  text-align: center;
}

.category-card-element {
  height: 100%;
  width: 100%;
}

.card-footer {
  border: none;
  background-color: #343a40;
}

.category-card-tittle {
  font-size: 16px;
  font-weight: bold;
  display: flex;
}

.category-card-options {
  position: absolute;
  right: 0;
  transform: translate(-50%, -15%);
}

.category-options-buttons {
  padding: 0;
  color: #7c849f;
  text-decoration: none;
  outline: none;
}
</style>
